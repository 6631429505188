import m from 'mithril';
import { Shadow, TextField } from 'polythene-mithril';
import { Banner, Container, UnitMenu } from '$components';
import { Item, Price, Validate } from '$models';
import { warning } from '$assets';
import { state } from '$app/state';

var Input;
var warningIsHidden = false;

const hideWarning = function() {
	warningIsHidden = true;
};

const onPriceUpdated = function(v) {
	for(var price of Input.Prices) {
		if(price.Location === '' || price.Price === '' || price.Quantity === '' || price.Unit === '') {
			return;
		}
	}

	v.state.underPriceLimit = state.underPriceLimit();
	v.state.underPriceLimit && Input.Prices.push(Price.newPrice());
};

export var EditItem = {
	defaultLabel: '',
	oninit: function(v) {		
		v.state = {
			underPriceLimit: state.underPriceLimit()
		};
		
		if(v.attrs.id !== undefined && v.attrs.id !== 'new') {
			var item = Item.find(v.attrs.id);
			if(item) {
				Input = item;
				onPriceUpdated(v);
			}
		} else {
			Input = {
				Label: EditItem.defaultLabel,
				Prices: v.state.underPriceLimit ? [Price.newPrice()] : []
			};
		}
	},
	view: function(v) {
		return m(
			Container,
			!v.state.underPriceLimit && !warningIsHidden ? m(
				Banner,
				{
					text: 'You have reached your free price limit',
					icon: warning,
					buttons: [
						{
							label: 'CONTINUE AS A FREE USER',
							onclick: hideWarning
						},
						{
							label: 'UPGRADE NOW',
							onclick: function() {
								m.route.set('/upgrade');
							}
						}
					]
				}
			) : '',
			m(
				'.pt2.ph2.bg-white.br3.mb3',
				m(
					TextField,
					{
						className: 'themed-textfield round-textfield',
						events: {
							oninput: m.withAttr('value', value => {
								Item.change(Input, 'Label', value);
							})
						},
						floatingLabel: true,
						dense: true,
						label: 'Label',
						required: true,
						value: Input.Label,
						validate: value => {
							return Validate.postProcess(Validate.itemLabel(value, Input.ItemID))
						},
						validateAtStart: Input.Label !== ''
					}
				)
			),
			Input.Prices.map(
				function(price) {
					var options = false;
					
					if(Input.Prices.length > 1) {
						for(var { Unit } of Input.Prices) {
							if(Unit) {
								options = {
									type: Price.unitOptions[Price.units[Unit][Price.TYPE]]
								};
								break;
							}
						}
					}
					
					options || (options = Price.unitOptions);
					
					return m(
						'.ba.b--light-silver.pt2.ph2.mb2.br3.bg-white.relative',
						m(
							TextField,
							{
								className: 'themed-textfield round-textfield',
								events: {
									oninput: m.withAttr('value', value => {
										Item.change(
											Input,
											'Prices',
											{
												key: 'Location',
												value: value,
												price: price
											}
										);
										onPriceUpdated(v);
									})
								},
								floatingLabel: true,
								dense: true,
								label: 'Location',
								value: price.Location,
								validate: value => {
									return Validate.postProcess(Validate.priceLocation(value));
								},
								validateAtStart: price.Location !== ''
							}
						),
						m(
							TextField,
							{
								className: 'themed-textfield round-textfield',
								events: {
									oninput: m.withAttr('value', value => {
										Item.change(
											Input,
											'Prices',
											{
												key: 'Price',
												value: value,
												price: price
											}
										);
										onPriceUpdated(v);
									})
								},
								floatingLabel: true,
								dense: true,
								label: 'Price',
								required: true,
								value: price.Price,
								validate: value => {
									return Validate.postProcess(Validate.pricePrice(value));
								},
								validateAtStart: price.Price !== ''
							}
						),
						m('.flex-row',
							m('.flex-auto',
								m(
									TextField,
									{
										className: 'themed-textfield round-textfield',
										events: {
											oninput: m.withAttr('value', value => {
												Item.change(
													Input,
													'Prices',
													{
														key: 'Quantity',
														value: value,
														price: price
													}
												);
												onPriceUpdated(v);
											})
										},
										floatingLabel: true,
										dense: true,
										label: 'Quantity',
										required: true,
										value: price.Quantity,
										validate: value => {
											return Validate.postProcess(Validate.priceQuantity(value));
										},
										validateAtStart: price.Quantity !== ''
									}
								)
							),
							m(
								'.flex-auto.ml2',
								m(
									UnitMenu,
									{
										label: 'Unit',
										text: price.Unit ? Price.labelByUnit[price.Unit] : '',
										value: price.Unit,
										onchange: function(value) {
											Item.change(
												Input,
												'Prices',
												{
													key: 'Unit',
													value: value,
													price: price
												}
											);
											onPriceUpdated(v);
										},
										options: options
									}
								)
							)
						),
						m(
							Shadow,
							{
								shadowDepth: 1,
								animated: true
							}
						)
					);
				}
			)
		);
	}
};