import m from 'mithril';
import { Button, Shadow, TextField } from 'polythene-mithril';
import { Container, Spinner, SVG } from '$components';
import { Credentials, Item } from '$models';
import { state } from '$app/state';
import { user } from '$assets';
import { Auth } from 'aws-amplify';

var authenticating = false;
var formError = false;
var usernameExists = false;
var forgotPassword = false;

const renderUsernameForm = function() {
	return m(
		TextField,
		{
			id: 'email-field',
			className: 'themed-textfield round-textfield',
			events: {
				oninput: m.withAttr('value', value => {
					formError = false;
					Credentials.setUsername(value);
				})
			},
			floatingLabel: true,
			label: 'Email',
			required: true,
			type: 'email',
			value: Credentials.username,
			valid: !formError,
			error: formError
		}
	);
}

const renderPasswordForm = function() {
	return [
		m(
			'.bg-crncher-purple.br3.pa2.mb2.white.flex-row.pointer',
			{
				onclick: function() {
					usernameExists = false;
				}
			},
			m(
				SVG,
				{
					svg: user,
					className: 'self-center mr1',
					color: 'white',
					width: '1rem',
					height: '1rem'
				}
			),
			Credentials.username
		),
		m(
			TextField,
			{
				id: 'password-field',
				className: 'themed-textfield round-textfield',
				events: {
					oninput: m.withAttr('value', value => Credentials.setPassword(value))
				},
				floatingLabel: true,
				label: 'Password',
				required: true,
				type: 'password',
				value: Credentials.password,
				valid: !formError,
				error: formError
			}
		)
	];
};

const renderForgotPassword = function() {
	return m(
		'div',
		m(
			'.bg-crncher-purple.br3.pa2.mb2.white.flex-row.pointer',
			{
				onclick: function() {
					usernameExists = false;
				}
			},
			m(
				SVG,
				{
					svg: user,
					className: 'self-center mr1',
					color: 'white',
					width: '1rem',
					height: '1rem'
				}
			),
			m(
				'.flex-auto.overflow-hidden.truncate',
				Credentials.username
			)
		),
		m(
			TextField,
			{
				className: 'themed-textfield round-textfield',
				events: {
					oninput: m.withAttr('value', value => {
						formError = false;
						Credentials.setForgotPasswordCode(value);
					})
				},
				floatingLabel: true,
				label: 'Code',
				required: true,
				value: Credentials.forgotPasswordCode
			}
		),
		m(
			TextField,
			{
				className: 'themed-textfield round-textfield',
				events: {
					oninput: m.withAttr('value', value => {
						formError = false;
						Credentials.setNewPassword(value);
					})
				},
				floatingLabel: true,
				label: 'New Password',
				type: 'password',
				required: true,
				value: Credentials.newPassword,
				valid: !formError,
				error: formError
			}
		)
	);
};

const renderUsernameButton = function() {
	return m(
		'.flex-row',
		m(
			'a.w-50.self-center',
			{
				href: '/signup',
				className: 'center white',
				oncreate: m.route.link
			},
			'Sign up'
		),
		m(
			Button,
			{
				className: 'w-50 round-button green-button',
				disabled: Credentials.username === '',
				events: {
					onclick: checkUsernameExists
				},
				label: 'Next',
				raised: true
			}
		)
	);
};

const renderPasswordButton = function() {
	return m(
		'.flex-row',
		m(
			'a.w-50.self-center.pointer',
			{
				className: 'center white',
				onclick: function() {
					Auth.forgotPassword(Credentials.username)
						.then(function() {
							forgotPassword = true;
							m.redraw();
						})
						.catch(function(e) {
							formError = e.message;
							m.redraw();
						});
				}
			},
			'Forgot Password'
		),
		m(
			Button,
			{
				className: 'w-50 round-button green-button',
				disabled: Credentials.password === '',
				events: {
					onclick: login
				},
				label: 'Sign in',
				raised: true
			}
		)
	);
};

const renderForgotPasswordButton = function() {
	return m(
		'.flex-row.justify-end',
		m(
			'div',
			{
				style: 'min-width: 50%;',
			},
			m(
				Button,
				{
					className: 'round-button green-button w-100',
					disabled: Credentials.forgotPasswordCode === '' || Credentials.newPassword === '',
					events: {
						onclick: async function() {
							Auth.forgotPasswordSubmit(
								Credentials.username,
								Credentials.forgotPasswordCode,
								Credentials.newPassword
							)
								.then(function() {
									Credentials.authenticate();
									m.route.set('/items');
								})
								.catch(function(e) {
									formError = e.message;
									m.redraw();
								});
						}
					},
					label: 'Set New Password',
					raised: true
				}
			)
		)
	);
};

const checkUsernameExists = function() {
	authenticating = true;
	formError = false;
	m.redraw();

	Credentials.usernameExists(Credentials.username).then(function(exists) {
		authenticating = false;

		if(exists) {
			usernameExists = true;
		} else {
			formError = 'Username does not exist';
		}

		m.redraw();

		exists && document.getElementById('password-field').querySelector('input').focus();
	});
};

const login = function() {
	authenticating = true;
	formError = false;
	m.redraw();

	Credentials.login({
		success: async function() {
			Credentials.authenticate();
			authenticating = false;
			state.items = false;
			await Item.load();
			m.route.set('/items');
		},
		error: function(message) {
			authenticating = false;
			formError = message;
			m.redraw();
		}
	});
};

export var Login = {
	oninit: function(v) {
		authenticating = false;
		formError = false;
		usernameExists = false;
		forgotPassword = false;
	},
	oncreate: function(v) {
		document.getElementById('email-field').querySelector('input').focus();
	},
	view: function(v) {
		return m(
			Container,
			m(
				'.mw6.center',
				forgotPassword
					? m(
						'.f4.white.mb2',
						'Check your email inbox for your verification code and choose a new password'
					)
					: '',
				m(
					'.ba.b--light-silver.pa3.mb2.w-100.br3.bg-white.relative',
					forgotPassword
						? renderForgotPassword()
						: (
							usernameExists
								? renderPasswordForm()
								: renderUsernameForm()
						),
					m(
						Shadow,
						{
							shadowDepth: 1,
							animated: true
						}
					)
				),
				m(
					'div',
					forgotPassword
						? renderForgotPasswordButton()
						: (
							usernameExists
								? renderPasswordButton()
								: renderUsernameButton()
						)
				),
				authenticating ? m(Spinner, 'Authenticating') : ''
			)
		);
	}
};

document.body.addEventListener('keyup', function(e) {
	if(e.keyCode == 13 && m.route.get() === '/login') {
		usernameExists ? login() : checkUsernameExists();
	}
});