var brochureware = document.getElementById('brochureware');
var containerWrapper = document.getElementById('container-wrapper');

export var Brochureware = {
	onbeforeremove: function(v) {
		brochureware.classList.add('dn');
		containerWrapper.classList.remove('dn');
	},
	oninit: function(v) {
		brochureware.classList.remove('dn');
		containerWrapper.classList.add('dn');
	},
	view: function(v) {
		return '';
	}
};