import { Auth } from 'aws-amplify';
import { state } from '$app/state';
import store from 'store';

var Credentials = {
	authenticated: false,
	confirmPassword: '',
	confirmationCode: '',
	forgotPasswordCode: '',
	newPassword: '',
	password: '',
	username: '',
	authenticate: function() {
		store.set('guest', false);
		Credentials.authenticated = true;

		Credentials.confirmPassword = '';
		Credentials.confirmationCode = '';
		Credentials.forgotPasswordCode = '';
		Credentials.newPassword = '';
		Credentials.password = '';
		Credentials.username = '';
	},
	canConfirmCode: function() {
		return Credentials.confirmationCode !== '';
	},
	canLogin: function() {
		return Credentials.username !== ''
			&& Credentials.password !== '';
	},
	canSignup: function() {
		return Credentials.username !== ''
			&& Credentials.password !== ''
			&& Credentials.confirmPassword !== ''
			&& Credentials.password === Credentials.confirmPassword;
	},
	checkSession: async({success, error}) => {
		var guest = store.get('guest');
		
		if(guest === undefined) {
			store.set('guest', true);
			guest = true;
		}

		if(guest) {
			error();
		} else {			
			try {
				await Auth.currentSession();
				Credentials.authenticate();
				success();
			} catch(e) {
				error();
			}
		}
	},
	confirmSignup: async({success, error}) => {
		try {
			await Auth.confirmSignUp(Credentials.username, Credentials.confirmationCode);
			await Auth.signIn(Credentials.username, Credentials.password);
			Credentials.authenticate();
			success();
		} catch(exception) {
			error(exception.message);
		}
	},
	hideWarning: function() {
		state.loginToBackupWarningHidden = true;
	},
	login: async({success, error}) => {
		try {
			await Auth.signIn(Credentials.username, Credentials.password);
			Credentials.authenticate();
			success();
		} catch(exception) {
			error(exception.message);
		}
	},
	logout: async() => {
		await Auth.signOut();
		Credentials.authenticated = false;
		Credentials.setUsername('');
		Credentials.setPassword('');
		state.clear();
		store.clearAll();
	},
	setConfirmationCode: function(value) {
		Credentials.confirmationCode = value;
	},
	setConfirmPassword: function(value) {
		Credentials.confirmPassword = value;
	},
	setForgotPasswordCode: function(value) {
		Credentials.forgotPasswordCode = value;
	},
	setNewPassword: function(value) {
		Credentials.newPassword = value;
	},
	setPassword: function(value) {
		Credentials.password = value;
	},
	setUsername: function(value) {
		Credentials.username = value;
	},
	signup: async({success, error}) => {
		try {
			await Auth.signUp({username: Credentials.username, password: Credentials.password});
			success();
		} catch(exception) {
			error(exception);
		}
	},
	usernameExists: function(username) {
		const code = '000000';
		return Auth.confirmSignUp(username, code, {
			// If set to False, the API will throw an AliasExistsException error if the phone number/email used already exists as an alias with a different user
			forceAliasCreation: false
		})
		.catch(err => {
			return err.code !== 'UserNotFoundException';
		});
	}
};

export var Credentials;