import m from 'mithril';
import { SVG as PolytheneSVG } from 'polythene-mithril';

export var SVG = {
	oninit: function(v) {
		var element = document.createElement('div');
		element.innerHTML = v.attrs.svg;
		var child = element.childNodes[0];
		child.style.width = v.attrs.width;
		child.style.height = v.attrs.height;
		v.state.html = child.outerHTML;
	},
	view: function(v) {
		return m(PolytheneSVG, {className: (v.attrs.color !== undefined ? v.attrs.color : 'white')+'-svg '+(v.attrs.className || '')}, m.trust(v.state.html));
	}
};