import m from 'mithril';
import stream from 'mithril/stream';
import { FAB, List, ListTile, Menu, Search, Shadow } from 'polythene-mithril';
import { EditItem } from './EditItem';
import { Banner, Container, Snackbar, Spinner, SVG } from '$components';
import { add, ellipsis, groceries, notes, warning } from '$assets';
import { Credentials, Item } from '$models';
import { addEvent } from '$app/helpers';
import { state } from '$app/state';
import uuidv1 from 'uuid/v1';

let showItemMenu = false;
let snackbarTimeout = false;

const positionMenuOnActiveItem = function(attempt) {
	attempt === undefined && (attempt = 1);

	if(!showItemMenu) {
		return;
	}

	let
	elem = document.getElementById('item-'+showItemMenu+'-ellipsis'),
	itemMenu = document.getElementById('item-menu');

	if(!elem || !itemMenu) {
		return;
	}

	let
	elemRect = elem.getBoundingClientRect(),
	panel = itemMenu.querySelectorAll('.pe-menu__panel');
	
	if(!panel.length) {
		++attempt < 5 && setTimeout(function() {
			positionMenuOnActiveItem(attempt);
		}, 10);
		return;
	}

	panel = panel[0];

	let
	top = elemRect.top - 5,
	left = elemRect.right - panel.clientWidth + 5;

	// menu will not overflow past page
	top + panel.clientHeight > window.innerHeight && (top = window.innerHeight - panel.clientHeight - 10);

	panel.style.position = 'fixed';
	panel.style.top = top+'px';
	panel.style.left = left+'px';
};

const renderItems = function(items) {
	return m(List, {
		border: true,
		className: 'bg-white',
		hoverable: true,
		tiles: items.map(function(item) {
			return m(
				ListTile,
				{
					title: m(
						'.flex-row',
						item.Label,
						Item.onGroceryList(item) ? m(
							SVG,
							{
								svg: groceries,
								className: 'ml2 self-center',
								color: 'light',
								width: '.8rem',
								height: '.8rem'
							}
						) : '',
						item.Note !== undefined && item.Note !== '' ? m(
							SVG,
							{
								svg: notes,
								className: 'ml2 self-center',
								color: 'light',
								width: '.8rem',
								height: '.8rem'
							}
						) : ''
					),
					subtitle: Item.subtitle(item),
					secondary: {
						id: 'item-'+item.ItemID+'-secondary',
						content: m(
							'.flex-row',							
							m(
								'.relative',
								{
									id: 'item-'+item.ItemID+'-ellipsis',
									onclick: function() {
										showItemMenu = item.ItemID;
										positionMenuOnActiveItem();
									}
								},
								m(
									SVG,
									{
										svg: ellipsis,
										color: 'dark',
										width: '1rem',
										height: '1rem'
									}
								)
							)
						)
					},
					events: {
						onclick: function() {
							item.onclick && item.onclick();
							m.route.set('/items/'+item.ItemID);
						}
					},
					hoverable: true
				}
			);
		})
	});
};

const routeOptionsForItem = function(ItemID) {
	const item = Item.find(ItemID);
	let options = [];

	options.push({
		text: 'Edit',
		onclick: function() {
			m.route.set('/items/'+ItemID)
		}
	});

	options.push({
		text: item.Note !== undefined && item.Note !== '' ? 'Edit Notes' : 'Add Notes',
		onclick: function() {
			m.route.set('/notes/'+ItemID)
		}
	});

	if(item.GroceryState !== undefined && item.GroceryState.Active) {
		options.push({
			text: 'Remove from Groceries',
			onclick: function() {
				var GroceryState = item.GroceryState;
				GroceryState.Active = false;
				Item.change(item, 'GroceryState', GroceryState);
				state.refreshGroceryState();
			}
		});
	} else {
		options.push({
			text: 'Add to Groceries',
			onclick: function() {
				var GroceryState = item.GroceryState || {};
				GroceryState.Active = true;
				GroceryState.LastAdded = new Date();
				Item.change(item, 'GroceryState', GroceryState);
				state.refreshGroceryState();
			}
		});
	}

	/*
	Item.hasValidPrice(item) && options.push({
		text: 'Compare',
		onclick: function() {
			m.route.set('/compare/'+ItemID)
		}
	});
	*/

	options.push({
		text: 'Delete',
		onclick: function() {
			state.recentlyDeletedItem = item;
			snackbarTimeout && clearTimeout(snackbarTimeout);
			snackbarTimeout = setTimeout(function() {
				state.recentlyDeletedItem = false;
				m.redraw();
			}, 4000);

			Item.remove(ItemID);
		}
	});

	return options;
};

export var Items = {
	oninit: async function(v) {
		v.state.visibleItems = state.items;
		v.state.filter = stream('');
	},
	view: function(v) {
		return m(
			Container,
			/*
			Credentials.authenticated ? m(
				'span.ml2.pointer.dim',
				{
					onclick: async function() {
						await Item.load();
						m.redraw();
					}
				},
				m(Icon, {icon: 'sync-alt'})
			) : ''
			*/
			state.items.length ? m(
				'div',
				!Credentials.authenticated && !state.loginToBackupWarningHidden && state.items.length ? m(
					Banner,
					{
						text: 'If you do not Sign Up, you may lose data.',
						icon: warning,
						buttons: [
							{
								label: 'CONTINUE AS GUEST',
								onclick: Credentials.hideWarning
							},
							{
								label: 'SIGN UP',
								onclick: function() {
									m.route.set('/signup');
								}
							}
						]
					}
				) : '',
				m(
					Search,
					{
						className: 'mb4',
						events: {
							oninput: e => {
								v.state.filter(e.target.value);

								if(e.target.value === '') {
									v.state.visibleItems = state.items;
									return;
								}

								const matches = state.itemFuzzySet ? state.itemFuzzySet.get(e.target.value) : [];
								const perfectMatch = matches && matches[0][0] === 1;
								v.state.visibleItems = matches ? matches.map(function(match, matchIndex) {
									return state.itemByLabel[match[1]];
								}) : [];

								!perfectMatch && v.state.visibleItems.push({
									ItemID: 'new',
									Label: 'Add "'+e.target.value+'"',
									onclick: function() {
										EditItem.defaultLabel = e.target.value;
										
										state.insertItem({
											ItemID: uuidv1(),
											Label: e.target.value,
											Prices: [],
											changes: [{
												Label: e.target.value
											}]
										});
			
										state.updateItemStore();
									}
								});
							}
						},
						fullWidth: true,
						textfield: { label: 'Search' },
						before: m(Shadow),
						value: v.state.filter()
					}
				),
				renderItems(v.state.visibleItems),
				m(
					'#item-menu',
					m(
						Menu,
						{
							backdrop: true,
							show: showItemMenu !== false,
							didHide: function() {
								showItemMenu = false;
							},
							content: m(
								List,
								routeOptionsForItem(showItemMenu).map(function(option) {
									return m(
										ListTile,
										{
											events: {
												onclick: option.onclick
											},
											title: option.text,
											ink: true
										}
									);
								})
							)
						}
					)
				)
			) : '',
			m(
				'.fixed.right-1.bottom-1.left-1.z-1.unclickable',
				state.recentlyDeletedItem ? m(
					Snackbar,
					{
						text: 'Deleted Item',
						buttons: [
							{
								onclick: state.undoDeleteAction,
								text: 'UNDO'
							}
						]
					}
				) : '',
				!state.items.length ? m('.white.f2.di', 'Add your first item to start saving money.') : '',
				m(
					'.fr',
					m(
						FAB,
						{
							className: 'themed-fab clickable ml1',
							events: {
								onclick: function() {
									EditItem.defaultLabel = '';
									m.route.set('/items/new');
								}
							},
							icon: {
								svg: {
									content: m.trust(add)
								}
							}
						}
					)
				)
			),
			state.loadingItems ? m(Spinner) : ''
		);
	}
};

addEvent(window, 'scroll', positionMenuOnActiveItem);
addEvent(window, 'resize', positionMenuOnActiveItem);