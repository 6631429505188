import { ButtonCSS, FABCSS, IconButtonCSS, SVGCSS, TextFieldCSS, ToolbarCSS } from 'polythene-css';
import { crncherGreen, crncherPurple } from './colors';

ButtonCSS.addStyle('.green-button', {
	color_light_text: '#333C44',
	color_light_background: crncherGreen,
	animation_duration: '100ms'
});

FABCSS.addStyle('.themed-fab', {
  color_light_background: crncherGreen,
  color_light: crncherPurple
});

IconButtonCSS.addStyle('.green-icon-button', {
	color_light_hover: crncherGreen
})

SVGCSS.addStyle('.white-svg', {
	color_light: '#fff'
});

SVGCSS.addStyle('.dark-svg', {
	color_light: '#333C44'
});

SVGCSS.addStyle('.light-svg', {
	color_light: '#dedede'
});

SVGCSS.addStyle('.green-svg', {
	color_light: crncherGreen
});

SVGCSS.addStyle('.purple-svg', {
	color_light: crncherPurple
});

TextFieldCSS.addStyle('.themed-textfield', {
	color_light_input_background: '#e3e0ff',
	input_padding_h: 16
});

ToolbarCSS.addStyle('.themed-toolbar', {
	color_light_background: crncherPurple,
	color_light_text: '#fff'
});