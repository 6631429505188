import m from 'mithril';
import { SVG } from './SVG';
import { IconButton as PoltheneIconButton } from 'polythene-mithril'; 

var size = '1.5rem';

export var IconButton = {
	view: function(v) {
		return m(
			PoltheneIconButton,
			{
				className: v.attrs.className,
				id: v.attrs.id,
				events: {
					onclick: v.attrs.onclick
				}
			},
			m(SVG, {svg: v.attrs.icon, width: size, height: size})
		);
	}
};