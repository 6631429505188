import m from 'mithril';
import { config } from '$app/config';
import { state } from '$app/state';
import { Item } from './Item';

const patterns = {
	double: /^[\d]*(.[\d]+){0,1}$/,
	integer: /^[\d]*$/,
	money: /^[\d]*(.[\d]{1,2}){0,1}$/
};

export var Validate = {
	first: function(checks) {
		for(let check of checks) {
			const { value, error } = check();
			
			if(!value) {
				return {
					value: false,
					error: error
				};
			}
		}

		return {
			value: true
		};
	},
	item: function(item) {
		let checks = [() => Validate.itemLabel(item.Label, item.ItemID)];

		if(Item.hasPriceData(item)) {
			checks.push(() => Validate.price(item.Prices[0]));
		}

		return Validate.first(checks);
	},
	itemLabel: function(value, ItemID) {
		return Validate.merge([
			() => Validate.notEmpty(value, 'Label'),
			() => Validate.maxLength(value, config.max.itemLabelLength, 'Label'),
			() => Validate.uniqueLabel(value, ItemID)
		]);
	},
	max: function(value, max, maxString, name) {
		return {
			value: parseFloat(value) <= max,
			error: name+' cannot exceed '+maxString
		};
	},
	maxLength: function(value, max, name) {
		return {
			value: value.length <= max,
			error: name+' cannot exceed '+max+' characters'
		};
	},
	merge: function(checks) {
		let errors = [];
		for(let check of checks) {
			const { value, error } = check();
			value || errors.push(error);
		}

		if(errors.length === 0) {
			return {
				value: true
			};
		}

		return {
			value: false,
			error: errors.join('<br>')
		};
	},
	notEmpty: function(value, name) {
		return {
			value: value !== '',
			error: name+' cannot be empty'
		}
	},
	postProcess: function(result) {
		if(result.value) {
			return undefined;
		}
		
		result.error = m.trust(result.error);
		return result;
	},
	price: function(price) {
		return Validate.merge([
			() => Validate.priceLocation(price.Location),
			() => Validate.pricePrice(price.Price),
			() => Validate.priceQuantity(price.Quantity),
			() => Validate.priceUnit(price.Unit)
		]);
	},
	priceLocation: function(value) {
		return Validate.maxLength(value, config.max.itemLabelLength, 'Location');
	},
	pricePrice: function(value) {
		return Validate.merge([
			() => Validate.notEmpty(value, 'Price'),
			() => Validate.regex(patterns.money, value, 'Price'),
			() => Validate.max(value, config.max.price, config.max.priceString, 'Price')
		]);
	},
	priceQuantity: function(value) {
		return Validate.merge([
			() => Validate.notEmpty(value, 'Quantity'),
			() => Validate.regex(patterns.double, value, 'Quantity'),
			() => Validate.max(value, config.max.quantity, config.max.quantityString, 'Quantity')
		]);
	},
	priceUnit: function(value) {
		return Validate.notEmpty(value, 'Unit');
	},
	regex: function(pattern, value, name) {
		return {
			value: RegExp(pattern).test(value),
			error: name+' is not in a valid format'
		};
	},
	uniqueLabel: function(Label, ItemID) {		
		for(let item of state.items) {
			if(item.ItemID !== ItemID && item.Label === Label) {
				return {
					value: false,
					error: 'Label must be unique'
				};
			}
		}

		return {
			value: true
		};
	}
};