import { Validate } from './Validate';
import { config } from '$app/config';

var SHORTHAND = 0, RATE = 1, TYPE = 2, BASE = 3;

var units = {
	/* length */
	meter: ['m', 1.0, 'length', 'meter'],
	inch: ['in', 0.0254, 'length', 'meter'],
	foot: ['ft', 0.3048, 'length', 'meter'],
	yard: ['yd', 0.9144, 'length', 'meter'],
  
	/* mass */
	kilogram: ['kg', 1.0, 'mass', 'kilogram'],
	pound: ['lbs', 0.45359237, 'mass', 'kilogram'],
	ounce: ['oz', 0.0283495231, 'mass', 'kilogram'],
	gram: ['g', 1e-3, 'mass', 'kilogram'],
  
	/* volume */
	liter: ['L', 1.0, 'volume', 'liter'],
	gallon: ['gal', 3.7854118, 'volume', 'liter'],
	quart: ['qt', 0.94635295, 'volume', 'liter'],
	pint: ['pt', 0.473176475, 'volume', 'liter'],
	'fluid-ounce': ['fl oz', 2.95735297e-2, 'volume', 'liter'],

	/* unit */
	unit: ['unit', 1.0, 'unit', 'unit'],
	dozen: ['dozen', 12.0, 'unit', 'unit']
};

var labelByUnit = {};
var unitOptions = {};
Object.keys(units).forEach(function(key) {
	var unit = units[key];
	var shorthand = unit[SHORTHAND];
	var type = unit[TYPE];

	if(unitOptions[type] === undefined) {
		unitOptions[type] = [];
	}

	labelByUnit[key] = shorthand;
	unitOptions[type].push({value: key, text: shorthand});
});

export var Price = {
	SHORTHAND,
	RATE,
	TYPE,
	BASE,

	detailedDescription: function(price) {
		return '$'+price.Price+' for '+price.Quantity+' '+Price.units[price.Unit][SHORTHAND]+(price.Location ? ' at '+price.Location : '');
	},
	find: function(item, PriceID) {
		if(item.Prices !== undefined) {
			for(let price of item.Prices) {
				if(price.PriceID === PriceID) {
					return price;
				}
			}
		}

		return false;
	},
	getBase: function(key) {
		return units[key][BASE];
	},

	/**
	 * PPBU is Price Per Base Unit, measured in $/meter, $/kilogram, or $/liter
	 */
	getPPBU: function(price) {
		var key = price.Unit;
		var unit = units[key];
		var base = unit[BASE];
		var PPU = parseFloat(price.Price)/parseFloat(price.Quantity);
		return base === key ? PPU : unit[RATE] * PPU;
	},

	isValidPrice: function(price) {
		return Validate.first([
			() => Validate.priceLocation(price.Location),
			() => Validate.pricePrice(price.Price),
			() => Validate.priceQuantity(price.Quantity),
			() => Validate.priceUnit(price.Unit)
		]).value;
	},
	newPrice: function() {
		return {
			Location: '',
			Price: '',
			Quantity: '',
			Unit: ''
		};
	},

	labelByUnit,
	unitOptions,
	units
};