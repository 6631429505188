import m from 'mithril';
import { Button } from 'polythene-mithril';
import Amplify from 'aws-amplify';
import { Brochureware, /*Compare,*/ EditItem, EditNote, Groceries, Items, Login, Notes, Perks, Signup, Upgrade } from '$containers';
import { Header, Sidebar, Spinner } from '$components';
import { Credentials, Item } from '$models';
import '$app/styles';
import { config } from '$app/config';
import { state } from '$app/state';

var brochureware = document.getElementById('brochureware');
var container = document.getElementById('container');
var containerWrapper = document.getElementById('container-wrapper');
var sidebar = document.getElementById('sidebar');

m.render(container, m(Spinner));
brochureware.classList.add('dn');
containerWrapper.classList.remove('dn');

Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		region: config.cognito.REGION,
		userPoolId: config.cognito.USER_POOL_ID,
		identityPoolId: config.cognito.IDENTITY_POOL_ID,
		userPoolWebClientId: config.cognito.APP_CLIENT_ID
	},
	API: {
		endpoints: [
			{
				name: 'items',
				endpoint: config.apiGateway.URL,
				region: config.apiGateway.REGION
			}
		]
	}
});

var lastRoute = null;
var route = function(key, component) {
	return {
		onmatch: function(params) {
			if(lastRoute !== key) {
				window.scrollTo(0, 0);
				lastRoute = key;
				document.body.setAttribute('data-route', key);
				Sidebar.open = false;

				state.syncUnsortedPrices();
				Item.sync();

				setTimeout(function() {
					Header.scroll();
					Header.render();					
				}, 10);
			}

			return component.onmatch ? component.onmatch() : component;
		}
	}
};

var authenticatedRoute = function(key, component) {
	return {
		onmatch: function() {
			if(Credentials.authenticated) {
				return route(key, component).onmatch();
			}

			m.route.set('/login');
		}
	};
};

var paidRoute = function(key, component) {
	return {
		onmatch: function() {
			return route(key, Credentials.authenticated && state.userIsPaid ? component : Perks).onmatch();
		}
	};
};

var unauthenticatedRoute = function(key, component) {
	return {
		onmatch: function() {
			if(!Credentials.authenticated) {
				return route(key, component).onmatch();
			}

			m.route.set('/');
		}
	}
};

const routing = function() {
	const routeDetails = [
		[Brochureware, '/'],
//		[Compare, ['/compare', '/compare/:id']],
		[Groceries, '/groceries'],
		[Items, '/items'],
		[EditItem, ['/items/new', '/items/:id']],
		[Login, '/login', unauthenticatedRoute],
		[Notes, '/notes', paidRoute],
		[EditNote, ['/notes/:id/new', '/notes/:id'], paidRoute],
		[Signup, '/signup', unauthenticatedRoute],
		[Upgrade, '/upgrade', authenticatedRoute]
	];
	
	let routeData = {};
	for(let routeDetail of routeDetails) {
		const component = routeDetail[0];
		const paths = Array.isArray(routeDetail[1]) ? routeDetail[1] : [routeDetail[1]];
		const call = routeDetail.length > 2 ? routeDetail[2] : route;
		for(let path of paths) {
			routeData[path] = call(path, component);
		}
	}

	m.route.prefix('');
	m.route(container, '/', routeData);
	m.mount(sidebar, Sidebar);
};

Credentials.checkSession({
	success: function() {
		state.items === false && Item.load();
		routing();
	},
	error: function() {
		if(Credentials.authenticated) {
			Credentials.logout();
			routing();
			m.route.set('/login');
		} else {
			if(state.items === false) {
				state.items = [];
			}

			routing();
		}
	}
});

document.querySelectorAll('.mithril-replace-button').forEach(function(button) {
	button.classList.remove('mithril-replace-button');

	var classes = [];
	button.hasAttribute('round') && classes.push('round-button');

	var anchor = button.querySelector('a');
	var href = anchor.getAttribute('href');

	var noRipple = button.hasAttribute('no-ripple');

	m.render(
		button,
		m(
			Button,
			{
				className: 'green-button '+classes.join(' '),
				events: {
					onclick: function() {
						EditItem.defaultLabel = '';
						m.route.set(href);
						noRipple && (button.querySelector('.pe-ripple').innerHTML = '');
					}
				},
				raised: true
			},
			m('span.pv2.ph3', anchor.innerHTML)
		)
	);
});

var onpopstate = window.onpopstate;
window.onpopstate = function() {
	onpopstate();
	Header.render();
};

if(process.env.NODE_ENV !== 'development' && 'serviceWorker' in navigator) {
	navigator.serviceWorker
		.register('/service-worker.js')
		.then(function() {});
}

state.refreshGroceryState();
state.refreshNoteState();