import m from 'mithril';
import { Button } from 'polythene-mithril';
import { SVG } from './SVG';

export var Banner = {
	view: function(v) {
		return m(
			'.bg-white.w-100.ph3.pt3.pb2.mb4',
			m(
				'.flex-row',
				m(
					SVG,
					{
						className: 'self-center',
						svg: v.attrs.icon,
						color: 'purple',
						width: '2rem',
						height: '2rem'
					}
				),
				m(
					'.ml3',
					v.attrs.text
				)
			),
			m(
				'.w-100.tr',
				v.attrs.buttons.map(function(button) {
					return m(
						Button,
						{
							events: {
								onclick: button.onclick
							}
						},
						m('span.pa1', button.label)
					);
				})
			)
		);
	}
};