import store from 'store';
import FuzzySet from 'fuzzyset.js';
import { IdleQueue } from 'idlize/IdleQueue.mjs';
import { Item, Price } from '$models';
import { config } from '$app/config';

export var state = {
	deletedItemIDs: store.get('deletedItemIDs') || [],
	deletedItemStoreUpdateQueued: false,
	groceries: [],
	items: store.get('items') || [],
	itemStoreUpdateQueued: false,
	loadingItems: false,
	loginToBackupWarningHidden: false,
	notes: [],
	recentlyDeletedItem: false,
	storeQueue: new IdleQueue({ ensureTasksRun: true }),
	unsortedPrices: store.get('unsortedPrices') || [],
	userIsPaid: false,
	addUnsortedPrice: function(item, price) {
		if(item.ItemID === undefined) {
			return;
		}

		for(var unsortedPrice of state.unsortedPrices) {
			if(unsortedPrice.ItemID === item.ItemID && unsortedPrice.PriceID === price.PriceID) {
				return;
			}
		}

		state.unsortedPrices.push({
			ItemID: item.ItemID,
			PriceID: price.PriceID
		});

		store.set('unsortedPrices', state.unsortedPrices);
	},
	clear: function() {
		state.items = [];
		state.groceries = [];
		state.notes = [];
		state.userIsPaid = false;
	},
	deletedItem: function(ItemID) {
		state.deletedItemIDs.push(ItemID);
		state.updateDeletedItemStore();
	},
	itemFuzzySet: false,
	insertItem: function(item) {
		for(var itemIndex = 0, length = state.items.length; itemIndex < length; itemIndex++) {
			if(item.Label < state.items[itemIndex].Label) {
				state.items.splice(itemIndex, 0, item);
				return;
			}
		}

		state.items.push(item);
	},
	refreshGroceryState: function() {
		state.groceries = state.items.filter(function(item) {
			return item.GroceryState && item.GroceryState.Active;
		});
	},
	refreshNoteState: function() {
		state.notes = state.items.filter(function(item) {
			if(item.Note !== undefined && item.Note) {
				typeof item.LatestNoteDate === 'string' && (item.LatestNoteDate = new Date(item.LatestNoteDate));
				return true;
			}

			return false;
		});

		state.notes.sort(function(a, b) {
			return b.LatestNoteDate - a.LatestNoteDate;
		});
	},
	removeItem: function(item) {
		state.items.splice(Item.findIndex(item.ItemID), 1);
	},
	syncUnsortedPrices: function() {
		for(var unsortedPrice of state.unsortedPrices) {
			var item = Item.find(unsortedPrice.ItemID);

			if(!item) {
				continue;
			}

			if(item.Prices !== undefined) {
				for(var priceIndex = 0; priceIndex < item.Prices.length; ++priceIndex) {
					var price = item.Prices[priceIndex];
					if(price.Location === '' && price.Price === '' && price.Quantity === '' && price.Unit === '') {
						item.Prices.splice(priceIndex, 1);
					}
				}
			}

			var price = Price.find(item, unsortedPrice.PriceID);
			Price.isValidPrice(price) && (price.PPBU = Price.getPPBU(price));

			Item.insertPrice(item, price);
		}
		
		state.updateItemStore();

		state.unsortedPrice = [];
		store.set('unsortedPrices', []);
	},
	underPriceLimit: function() {
		if(state.userIsPaid) {
			return true;
		}

		let priceCount = 0;
		for(let item of state.items) {
			if(item.Prices !== undefined) {
				for(let price of item.Prices) {
					if(price.Location !== undefined || price.Price !== undefined || price.Quantity !== undefined || price.Unit !== undefined) {
						if(++priceCount == config.limits.price) {
							return false;
						}
					}
				}
			}
		}

		return true;
	},
	undoDeleteAction: function() {
		state.insertItem(state.recentlyDeletedItem);
		state.recentlyDeletedItem = false;
	},
	updateItemStore: function() {
		if(state.itemStoreUpdateQueued) {
			return;
		}

		let fuzzySetData = [];
		state.itemByLabel = [];

		state.items.forEach(function(item) {
			fuzzySetData.push(item.Label);
			state.itemByLabel[item.Label] = item;
		});

		state.itemFuzzySet = FuzzySet(fuzzySetData);

		state.itemStoreUpdateQueued = true;
		state.storeQueue.pushTask(function() {
			state.itemStoreUpdateQueued = false;
			store.set('items', state.items);
		});
	},
	updateDeletedItemStore: function() {
		if(state.deletedItemStoreUpdateQueued) {
			return;
		}

		state.deletedItemStoreUpdateQueued = true;
		state.storeQueue.pushTask(function() {
			state.deletedItemStoreUpdateQueued = false;
			store.set('deletedItemIDs', state.deletedItemIDs);
		});
	}
};