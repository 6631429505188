export const addEvent = function(object, type, callback) {
    if(object.addEventListener) {
    	object.addEventListener(type, callback, false);
    } else if(object.attachEvent) {
    	object.attachEvent('on'+type, callback);
    } else {
    	object['on'+type] = callback;
    }
};

export const prettyDate = function(date) {
	typeof date === 'string' && (date = new Date(date));
  	return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
};