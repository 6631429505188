import m from 'mithril';
import { MaterialDesignSpinner } from 'polythene-mithril';

export var Spinner = {
	view: function(v) {
		return m(
			'.fixed.top-0.left-0.bottom-0.right-0',
			m(
				'.absolute-centered', 
				m(
					MaterialDesignSpinner,
					{
						show: true,
						size: 'fab'
					}
				)
			)
		);
	}
};