import m from 'mithril';
import { Drawer, Icon, List, ListTile } from 'polythene-mithril';
import { compare, groceries, home, items, logout, notes } from '$assets';
import { Credentials } from '$models';
import { state } from '$app/state';

var nav = function() {
	var options = [];

	options.push({
		title: 'Home',
		icon: home,
		onclick: function() {
			m.route.set('/');
		},
		selected: function() {
			return m.route.get() === '/';
		}
	});

	options.push({
		title: 'Items',
		icon: items,
		onclick: function() {
			m.route.set('/items');
		},
		selected: function() {
			return m.route.get() === '/items';
		}
	});

	options.push({
		title: 'Notes',
		icon: notes,
		onclick: function() {
			m.route.set('/notes');
		},
		selected: function() {
			return m.route.get() === '/notes';
		}
	});
		
	options.push({
		title: 'Groceries',
		icon: groceries,
		onclick: function() {
			m.route.set('/groceries');
		},
		selected: function() {
			return m.route.get() === '/groceries';
		}
	});

	/*
	options.push({
		title: 'Compare',
		icon: compare,
		disabled: state.items.length <= 1,
		onclick: function() {
			m.route.set('/compare');
		},
		selected: function() {
			return m.route.get() === '/compare';
		}
	});
	*/

	Credentials.authenticated && options.push({
		title: 'Logout',
		icon: logout,
		onclick: function() {
			Credentials.logout();
			m.route.set('/');
		},
		selected: function() {
			return false;
		}
	});

	return m(List, {
		tiles: options.map(function(tile) {
			return m(
				ListTile,
				{
					title: tile.title,
					front: tile.icon ? m(
						Icon,
						{
							svg: {
								content: m.trust(tile.icon)
							}
						}
					) : undefined,
					events: {
						onclick: function() {
							Sidebar.open = false;
							tile.onclick && tile.onclick();
						}
					},
					selected: tile.selected(),
					disabled: tile.disabled !== undefined && tile.disabled,
					hoverable: true,
					navigation: true,
					ink: true
				}
			);
		})
	});
};

var Sidebar = {
	open: false,
	view: function(v) {
		return m(
			Drawer,
			{
				backdrop: true,
				className: 'themed-drawer',
				content: nav(),
				didHide: function() {
					Sidebar.open = false;
				},
				fixed: true,
				show: Sidebar.open,
				shadowDepth: 1
			}
		);
	}
};

export var Sidebar;