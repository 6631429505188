import m from 'mithril';

export var Container = {
	view: function(v) {
		return [
			m(
				'section.relative.w-100.flex-column.flex-nowrap.flex-auto.items-stretch.pt4-l.pt4-m.pb4.ph5-ns.ph4.pt3',
				m(
					'.fixed.top-0.left-0.right-0.bottom-0', 
					m(
						'.hero.min-vh-100',
						m('.hero__overlay .hero__overlay--gradient'),
						m('.hero__mask'),
						m('.hero__inner')
					),
				),
				m(
					'.relative',
					{
						style: 'min-width: 5rem; width: 70vw; max-width: 1024px;'
					},
					v.children
				)
			)
		];
	}
};