import m from 'mithril';
import { Button } from 'polythene-mithril';

export var Snackbar = {
	open: false,
	view: function(v) {
		return m(
			'.w-100.bg-crncher-green.br2.ph2.flex-row.mb2',
			m('.flex-auto.self-center', v.attrs.text),
			v.attrs.buttons.map(function(button) {
				return m(
					Button,
					{
						className: 'clickable',
						events: {
							onclick: button.onclick
						}
					},
					m('.crncher-purple.b', button.text)
				);
			})
		);
	}
};