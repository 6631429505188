// fontawesome
import apple from './svg/solid/apple-alt.svg';
import bars from './svg/solid/bars.svg';
import ellipsisV from './svg/solid/ellipsis-v.svg';
import equals from './svg/solid/equals.svg';
import exclamationTriangle from './svg/solid/exclamation-triangle.svg';
import greaterThanEqual from './svg/solid/greater-than-equal.svg';
import greaterThan from './svg/solid/greater-than.svg';
import home from './svg/solid/home.svg';
import lessThan from './svg/solid/less-than.svg';
import list from './svg/solid/list-ul.svg';
import notEqual from './svg/solid/not-equal.svg';
import plus from './svg/solid/plus.svg';
import save from './svg/solid/save.svg';
import signIn from './svg/solid/sign-in-alt.svg';
import signOut from './svg/solid/sign-out-alt.svg';
import stickyNote from './svg/solid/sticky-note.svg';
import sync from './svg/solid/sync-alt.svg';
import user from './svg/solid/user.svg';

// custom
import logo from './svg/logo.svg';
import title from './svg/title.svg';

export const add = plus;
export const compare = greaterThanEqual;
export const compareOperators = {
	'>': greaterThan,
	'<': lessThan,
	'=': equals,
	'!=': notEqual
};
export const ellipsis = ellipsisV;
export const groceries = apple;
export const items = list;
export const login = signIn;
export const logout = signOut;
export const menu = bars;
export const notes = stickyNote;
export const refresh = sync;
export const warning = exclamationTriangle;

export { home, logo, save, title, user };