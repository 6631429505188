import m from 'mithril';
import { BillingForm, Container } from '$components';
import { API } from 'aws-amplify';
import { config } from '$app/config';

const stripe = Stripe(config.stripe.STRIPE_KEY);

var loading = false;

const billUser = function(details) {
	return API.post('items', '/billing', {
		body: details
	});
};

export var Upgrade = {
	view: function(v) {
		return m(
			Container,
			m(
				BillingForm,
				{
					onsubmit: async function(storage, { token, error }) {
						if(error) {
							// TODO handle without an alert
							alert(error);
							return;
						}

						loading = true;
						
						try {							
							await billUser({
								storage,
								source: token.id
							});
						
							// TODO handle without an alert
							alert('Your card has been charged successfully');
							m.route.set('/items');
						} catch(e) {
							alert(e);
							loading = false;
						}
					},
					stripe: stripe
				}
			)
		);
	}
};