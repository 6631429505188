import m from 'mithril';
import { Toolbar, ToolbarTitle } from 'polythene-mithril';
import { IconButton } from './IconButton';
import { Sidebar } from './Sidebar';
import { SVG } from './SVG';
import { Credentials } from '$models';
import { login, logo, menu, title } from '$assets';

var logoSize = '3rem';
var navConverter = document.getElementById('navConverter');
var titleHeight = 2.2;
var titleWidth = titleHeight*4.473684210526316;
titleHeight += 'rem';
titleWidth += 'rem';

const crncherTitle = function() {
	return m(
		'div.fl.w-100',
		m('.fl.relative.db-ns.dn', {style: 'top: .1rem;'}, m(SVG, {color: 'white', svg: logo, width: logoSize, height: logoSize})),
		m('.fl.ml2.relative.top-0.top-05-ns', m(SVG, {color: 'green', svg: title, width: titleWidth, height: titleHeight}))
	);
};

const addClass = function(newClass, classes) {
	classes.indexOf(newClass) === -1 && classes.push(newClass); 
	return classes;
};

const removeClass = function(oldClass, classes) {
	var index = classes.indexOf(oldClass);
	index !== -1 && classes.splice(index, 1);
	return classes;
}

const toggleClass = function(targetClass, classes, condition) {
	return condition ? addClass(targetClass, classes) : removeClass(targetClass, classes);
}

const getOffset = function(el) {
	var _x = 0;
	var _y = 0;

	while(el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
		_x += el.offsetLeft - el.scrollLeft;
		_y += el.offsetTop - el.scrollTop;
		el = el.offsetParent;
	}
	
	return { top: _y, left: _x };
};

const onScroll = function(route) {
	route === undefined && (route = m.route.get());
	var transparent = (window.pageYOffset || document.documentElement.scrollTop) <= Math.abs(getOffset(navConverter).top-60);
	var transparentClass = 'transparent-toolbar';
	var wasTransparent = extraClasses.indexOf(transparentClass) !== -1;

	extraClasses = toggleClass('animated-toolbar', extraClasses, true);

	if(transparent !== wasTransparent) {
		extraClasses = toggleClass(transparentClass, extraClasses, transparent);
	}
};

const titleFromRoute = function() {
	var routeParts = m.route.get().split('/');

	switch(routeParts[1]) {
//		case 'compare': return 'Compare';
		case 'groceries': return 'Groceries';
		case 'items': {
			return routeParts.length > 2
				? (routeParts[2] === 'new' ? 'New Item' : 'Edit Item')
				: 'Items';
		}
		case 'login': return 'Sign in';
		case 'notes': {
			return routeParts.length > 2
				? (routeParts.length > 3 && routeParts[3] === 'new' ? 'New Note' : 'Edit Note')
				: 'Notes';
		}
		case 'signup': return 'Sign up';
		default: return crncherTitle();
	}
};

var header = document.getElementById('header');
var extraClasses = [];

export var Header = {
	scroll: function() {
		onScroll();
	},
	render: function() {
		m.render(header, m(Header));
	},
	view: function(v) {
		return m(
			Toolbar,
			{
				className: 'themed-toolbar '+extraClasses.join(' ')
			},
			[
				m(
					IconButton,
					{
						icon: menu,
						onclick: function() {
							Sidebar.open = true;
						}
					}
				),
				m(
					ToolbarTitle,
					{
						text: titleFromRoute()
					}
				),
				!Credentials.authenticated && m.route.get() !== '/login' ? m(
					IconButton,
					{
						icon: login,
						onclick: function() {
							m.route.set('/login');
						}
					}
				) : ''
			]
		);
	}
};

window.addEventListener('scroll', function() {
	onScroll();
	Header.render();
});