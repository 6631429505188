import m from 'mithril';
import store from 'store';
import { API } from 'aws-amplify';
import { Credentials, Price, Validate } from '$models';
import { warning } from '$assets';
import { SVG } from '$components';
import { state } from '$app/state';
import { config } from '$app/config';
import uuidv1 from 'uuid/v1';

export var Item = {
	bestPrice: function(item) {
		return Item.hasValidPrice(item) ? item.Prices[0] : false;
	},
	change: function(item, key, value) {
		item.changes === undefined && (item.changes = {});

		let date = new Date();

		if(key === 'Prices') {
			let price = value.price;

			if(price.PriceID === undefined) {				
				price.isNew = true;
				price.PriceID = uuidv1();
				price.DateCreated = date;
			}

			price[value.key] = value.value;
			price.DateUpdated = date;

			if(value.key !== 'Location' && Price.isValidPrice(price)) {
				state.addUnsortedPrice(item, price);
			}

			item.changes.Prices === undefined && (item.changes.Prices = {});
			item.changes.Prices[price.PriceID] === undefined && (item.changes.Prices[price.PriceID] = {});
			price.isNew && (item.changes.Prices[price.PriceID].isNew = true);
			item.changes.Prices[price.PriceID][value.key] = value.value;
			item.changes.Prices[price.PriceID].DateUpdated = price.DateUpdated;
		} else {
			item[key] = value;
			item.changes[key] = value;

			if(key === 'Label') {
				if(item.ItemID === undefined) {
					item.ItemID = uuidv1();
					item.isNew = true;
				} else {
					state.removeItem(item);
				}
	
				state.insertItem(item);
			} else if(key === 'Note') {
				item.LatestNoteDate = date;
				item.changes.LatestNoteDate = date;
			}
		}

		item.DateUpdated = date;
		item.changes.DateUpdated = date;

		state.updateItemStore();
	},
	detailedBestPriceDescription: function(item) {
		const bestPrice = Item.bestPrice(item);
		return bestPrice ? 'Best Price: '+Price.detailedDescription(bestPrice) : null;
	},
	find: function(id) {
		for(let item of state.items) {
			if(item.ItemID === id) {
				return item;
			}
		}

		return false;
	},
	findByLabel: function(Label) {
		for(let item of state.items) {
			if(item.Label.toLowerCase() === Label.toLowerCase()) {
				return item;
			}
		}

		return false;
	},
	findIndex: function(id) {
		let index = 0;
		for(let item of state.items) {
			if(item.ItemID === id) {
				return index;
			}

			++index;
		}

		return false;
	},
	hasPriceData: function(item) {
		if(item.Prices === undefined || !item.Prices.length) {
			return false;
		}

		var price = item.Prices[0];
		if(price.Location !== '' || price.Price !== '' || price.Quantity !== '' || price.Unit !== '') {
			return true;
		}

		return false;
	},
	hasValidPrice: function(item) {
		return item.Prices !== undefined && item.Prices.length && Price.isValidPrice(item.Prices[0]);
	},
	insertPrice: function(item, price) {
		let insertIndex = false;
		let matchedIndex = false;

		item.Prices === undefined && (item.Prices = []);

		for(let priceIndex = 0, length = item.Prices.length; priceIndex < length; priceIndex++) {
			if(insertIndex === false && price.PPBU < item.Prices[priceIndex].PPBU) {
				insertIndex = priceIndex;
			}

			if(price.PriceID === item.Prices[priceIndex].PriceID) {
				matchedIndex = priceIndex;
			}
		}

		if(matchedIndex !== false) {
			if(matchedIndex === insertIndex) {
				return;
			}

			item.Prices.splice(matchedIndex, 1);
			matchedIndex < insertIndex && --insertIndex;
		}

		if(insertIndex !== false) {
			item.Prices.splice(insertIndex, 0, price);
		} else {
			item.Prices.push(price);
		}
	},
	isValidItem: function(item) {
		const unique = Validate.uniqueLabel(item.Label, item.ItemID);
		if(!unique) {
			item.errorMessage = 'Item Label unique be unique';
			return false;
		}

		if(!Validate.maxLength(item.Label, config.max.itemLabelLength)) {
			item.errorMessage = 'Item Label cannot exceed '+config.max.itemLabelLength;
			return false;
		}

		return false;
	},
	load: function() {
		state.loadingItems = true;
		return API.post('items', '/items', { body: {} }).then(function(syncResults) {
			var { status, items } = syncResults;

			if(status === true) {
				state.items = items;
				state.loadingItems = false;
				Item.sort();
				m.redraw();
				state.updateItemStore();
			}
		});
	},
	onGroceryList: function(item) {
		return item.GroceryState !== undefined && item.GroceryState.Active;
	},
	remove: async function(ItemID) {
		state.deletedItem(ItemID);
		state.items.splice(Item.findIndex(ItemID), 1);
		m.redraw();
		state.updateItemStore();
	},
	sort: function() {
		state.items.sort(function(a, b) {
			return a.Label < b.Label ? -1 : 1;
		});
	},
	subtitle: function(item) {
		const { value, error } = Validate.item(item);

		if(!value) {
			return m(
				'.flex-row',
				m(
					SVG,
					{
						className: 'fl mr2',
						svg: warning,
						color: 'purple',
						width: '1rem',
						height: '1rem'
					}
				),
				m('.red', m.trust(error))
			);
		}
		
		if(item.Prices === undefined || !item.Prices.length) {
			return '';
		}

		return Item.detailedBestPriceDescription(item);
	},
	sync: async function() {
		if(!Credentials.authenticated) {
			return;
		}

		const date = new Date();

		let data = [];
		let changedItems = []; // only includes updated items as they may have updates from a separate session
		for(let item of state.items) {
			if(!item.changes) {
				continue;
			}

			let update = {
				ItemID: item.ItemID,
				changes: item.changes
			};

			if(item.isNew) {
				update.isNew = true;
			} else {
				changedItems.push(item);
			}

			data.push(update);
		}

		for(let ItemID of state.deletedItemIDs) {
			data.push({
				isDeleted: true,
				ItemID: ItemID
			});
		}

		if(data.length) {
			let body = {
				updates: data
			};
			const lastItemSync = store.get('lastItemSync');
			lastItemSync && (body.lastSync = lastItemSync);
			
			process.env.NODE_ENV === 'development' && console.log('syncing', JSON.stringify(body));
			const syncResults = await API.post('items', '/items', { body: body });

			for(let changedItem of changedItems) {
				// TODO don't splice syncResults.status === false and failedItemIDs
				state.removeItem(changedItem);
			}

			var latestItemIDs = [];
			for(let latestItem of syncResults.items) {
				latestItemIDs.push(latestItem.ItemID);
				state.insertItem(latestItem);
			}

			state.deletedItemIDs = [];
			for(let ItemID of state.deletedItemIDs) {
				if(latestItemIDs.indexOf(ItemID) >= 0) {
					state.deletedItemIDs.push(ItemID);
				}
			}

			state.updateItemStore();
			state.updateDeletedItemStore();
			store.set('lastItemSync', date);
		}
	}
};