import m from 'mithril';
import { List, ListTile } from 'polythene-mithril';
import { Container } from '$components';
import { state } from '$app/state';
import { prettyDate } from '$app/helpers';

const renderNotes = function() {
	return [
		m(List, {
			border: true,
			hoverable: true,
			style: {
				backgroundColor: '#fff'
			},
			tiles: [
				state.notes.map(function(note, noteIndex) {
					return m(
						'div',
						m(
							ListTile,
							{
								className: 'center-secondary',
								events: {
									onclick: function() {
										m.route.set('/notes/'+note.ItemID+'/new');
									}
								},
								title: note.Label,
								highSubtitle: note.Note,
								secondary: {
									content: note.LatestNoteDate ? m('span', prettyDate(note.LatestNoteDate)) : ''
								},
								hoverable: true
							}
						)
					);
				})
			]
		}),
		m('.h1')
	];
};

var itemsWithoutNotes = [];

const renderItemsWithoutNotes = function() {
	return [
		m('.f4.white', 'Add a note to an Item'),
		m(List, {
			border: true,
			hoverable: true,
			style: {
				backgroundColor: '#fff'
			},
			tiles: [
				itemsWithoutNotes.map(function(note, noteIndex) {
					return m(
						'div',
						m(
							ListTile,
							{
								events: {
									onclick: function() {
										m.route.set('/notes/'+note.ItemID+'/new');
									}
								},
								title: note.Label,
								hoverable: true
							}
						)
					);
				})
			]
		})
	];
};

export var Notes = {
	oninit: function(v) {
		state.refreshNoteState();
		itemsWithoutNotes = state.items.filter(function(item) {
			return item.Note === undefined || !item.Note;
		});
	},
	view: function(v) {
		return m(
			Container,
			m(
				'div',
				state.notes.length ? renderNotes() : '',
				itemsWithoutNotes.length ? renderItemsWithoutNotes() : ''
			)
		);
	}
};