import m from 'mithril';
import { Button, Shadow, TextField } from 'polythene-mithril';
import { Container, Spinner } from '$components';
import { Credentials } from '$models';

var authenticating = false;
var awaitingConfirmation = false;
var formError = false;

const renderAwaitingConfirmation = function(onclick) {
	return [
		m(
			TextField,
			{
				className: 'themed-textfield round-textfield',
				events: {
					oninput: m.withAttr('value', value => Credentials.setConfirmationCode(value))
				},
				floatingLabel: true,
				label: 'Confirmation Code',
				required: true,
				value: Credentials.confirmationCode,
				valid: !formError,
				error: formError
			}
		)
	];
};

const renderForm = function() {
	return [
		m(
			TextField,
			{
				id: 'username-field',
				className: 'themed-textfield round-textfield',
				events: {
					oninput: m.withAttr('value', value => {
						Credentials.setUsername(value);
						formError = false;
					})
				},
				floatingLabel: true,
				label: 'Email',
				required: true,
				type: 'email',
				value: Credentials.username,
				valid: !formError,
				error: formError
			}
		),
		m(
			TextField,
			{
				className: 'themed-textfield round-textfield',
				events: {
					oninput: m.withAttr('value', value => Credentials.setPassword(value))
				},
				floatingLabel: true,
				label: 'Password',
				required: true,
				type: 'password',
				value: Credentials.password
			}
		),
		m(
			TextField,
			{
				className: 'themed-textfield round-textfield',
				events: {
					oninput: m.withAttr('value', value => Credentials.setConfirmPassword(value))
				},
				floatingLabel: true,
				label: 'Confirm Password',
				required: true,
				type: 'password',
				value: Credentials.confirmPassword
			}
		)
	];
};

const renderSignupButton = function() {
	return m(
		'div',
		m(
			Button,
			{
				className: 'w-100 green-button round-button',
				disabled: !Credentials.canSignup(),
				events: {
					onclick: onSubmitSignup
				},
				label: 'Sign up',
				raised: true
			}
		)
	);
};

const renderConfirmationButton = function() {
	return m(
		'div',
		m(
			Button,
			{
				className: 'w-100 green-button round-button',
				disabled: !Credentials.canConfirmCode(),
				events: {
					onclick: onSubmitConfirmation
				},
				label: 'Confirm',
				raised: true
			}
		)
	);
};

const onSubmitSignup = function() {
	authenticating = true;
	formError = false;

	Credentials.signup({
		success: function() {
			authenticating = false;
			awaitingConfirmation = true;
			m.redraw();
		},
		error: function(exception) {
			authenticating = false;
			formError = exception.message;
			document.getElementById('username-field').focus();
			m.redraw();
		}
	});
};

const onSubmitConfirmation = function() {
	authenticating = true;
	formError = false;
	
	Credentials.confirmSignup({
		success: function() {
			authenticating = false;
			awaitingConfirmation = false;
			m.route.set('/items');
		},
		error: function(message) {
			authenticating = false;
			formError = message;
			m.redraw();
		}
	});
}

export var Signup = {
	oninit: function(v) {
		authenticating = false;
		awaitingConfirmation = false;
		formError = false;
	},
	oncreate: function(v) {
		document.getElementById('username-field').querySelector('input').focus();
	},
	view: function(v) {
		return m(
			Container,
			m(
				'.mw6.center.flex-column',
				m(
					'.ba.b--light-silver.pa3.mb2.w-100.mw6.br3.bg-white.relative',
					awaitingConfirmation ? renderAwaitingConfirmation() : renderForm(),
					m(
						Shadow,
						{
							shadowDepth: 1,
							animated: true
						}
					)
				),
				m(
					'.pa3.self-end',
					{
						style: 'min-width: 10rem;'
					},
					awaitingConfirmation ? renderConfirmationButton() : renderSignupButton()
				),
				authenticating ? m(Spinner, 'Authenticating') : ''
			)
		);
	}
};