const cost = 10;
const priceLimit = 20;
const maxPrice = 1000000;
const maxQuantity = 1000000;

const getString = function(value) {
	switch(value) {
		case 5: return 'five';
		case 10: return 'ten';
		case 15: return 'fifteen';
		case 20: return 'twenty';
		case 25: return 'twenty five';
		case 30: return 'thirty';
		case 40: return 'forty';
		case 50: return 'fifty';
		case 1000000: return 'one million';
	}
};

const STRIPE_KEY = process.env.NODE_ENV === 'development'
	? 'pk_test_Gy49teboqvNJLvIsf2hETBcL'
	: 'pk_live_yppC8hYoIQlMW99L7jNwCFYD';

exports.config = {
	apiGateway: {
		REGION: 'us-east-2',
		URL: 'https://x0d8i7nnlj.execute-api.us-east-2.amazonaws.com/dev'
	},
	cognito: {
		REGION: 'us-east-2',
		USER_POOL_ID: 'us-east-2_qk7QqmrgI',
		APP_CLIENT_ID: '5neokj4m1in650aug8k9410m7g',
		IDENTITY_POOL_ID: 'us-east-2:acadb6fe-7e03-41b0-b4cc-8aed12a57467'
	},
	cost: cost,
	costString: getString(cost),
	stripe: {
		STRIPE_KEY: STRIPE_KEY
	},
	limits: {
		price: priceLimit,
		priceString: getString(priceLimit)
	},
	max: {
		itemLabelLength: 25,
		itemNoteLength: 300,
		priceLocationLength: 25,
		price: maxPrice,
		quantity: maxQuantity,
		priceString: getString(maxPrice),
		quantityString: getString(maxQuantity)
	}
};