import m from 'mithril';
import { Button, Checkbox, List, ListTile, Menu, TextField } from 'polythene-mithril';
import { Container } from '$components';
import { Item } from '$models';
import { state } from '$app/state';
import { config } from '$app/config';
import { ellipsis } from '$assets';
import uuidv1 from 'uuid/v1';
import { addEvent } from '$app/helpers';

var Input;
var selectedGroceryIndex;

let showItemMenu = false;

const focusGroceryLabel = function() {
	setTimeout(function() {
		var wrapper = document.getElementById('grocery-label');

		if(wrapper) {
			var input = wrapper.querySelector('input');
			input && input.focus();
		}
	}, 50);
};

const newGrocery = function() {
	if(Input.length > config.max.itemLabelLength) {
		return;
	}

	const match = Item.findByLabel(Input);

	let GroceryState = {
		Active: true,
		LastAdded: new Date()
	};

	if(match !== false) {
		match.GroceryState = GroceryState;
	} else {
		state.insertItem({
			ItemID: uuidv1(),
			Label: Input,
			Prices: [],
			GroceryState: GroceryState,
			changes: [{
				Label: Input,
				GroceryState: GroceryState
			}]
		});
	}

	state.updateItemStore();
	state.refreshGroceryState();

	selectedGroceryIndex = false;
};

const positionMenuOnActiveItem = function(attempt) {
	attempt === undefined && (attempt = 1);

	if(!showItemMenu) {
		return;
	}

	let
	elem = document.getElementById('item-'+showItemMenu+'-ellipsis'),
	itemMenu = document.getElementById('item-menu');

	if(!elem || !itemMenu) {
		return;
	}

	let
	elemRect = elem.getBoundingClientRect(),
	panel = itemMenu.querySelectorAll('.pe-menu__panel');
	
	if(!panel.length) {
		++attempt < 5 && setTimeout(function() {
			positionMenuOnActiveItem(attempt);
		}, 10);
		return;
	}

	panel = panel[0];

	let
	top = elemRect.top - 5,
	left = elemRect.right - panel.clientWidth + 5;

	// menu will not overflow past page
	top + panel.clientHeight > window.innerHeight && (top = window.innerHeight - panel.clientHeight - 10);

	panel.style.position = 'fixed';
	panel.style.top = top+'px';
	panel.style.left = left+'px';
};

const renderDialog = function() {
	return m(
		'.absolute.top-0.bg-light-gray.left-0.w5.pa3.z-1',
		m('.f3', 'Add a Grocery'),
		m(
			TextField,
			{
				id: 'grocery-label',
				events: {
					oninput: m.withAttr('value', value => Input = value)
				},
				required: true,
				value: Input,
				validate: value => {
					if(value.length > config.max.itemLabelLength) {
						return {
							value: false,
							error: 'Label cannot exceed '+config.max.itemLabelLength+' characters'
						};
					}
				}
			}
		),
		m(
			'.tr',
			m(
				Button,
				{
					className: 'mr2',
					events: {
						onclick: function() {
							selectedGroceryIndex = false;
						}
					}
				},
				m('span.pa2', 'Cancel')
			),
			m(
				Button,
				{
					events: {
						onclick: function() {
							newGrocery();
						}
					}
				},
				m('span.pa2', 'Save')
			)
		)
	);
};

const renderGroceries = function() {
	return m(List, {
		border: true,
		style: {
			backgroundColor: '#fff'
		},
		tiles: [
			state.groceries.map(function(grocery, groceryIndex) {
				return m(
					'div',
					m(
						ListTile,
						{
							title: grocery.Label,
							front: m(
								Checkbox,
								{
									onChange: function() {
										selectedGroceryIndex = false;
										grocery.GroceryState.Active = false;
										state.updateItemStore();
									}
								}
							),
							subtitle: Item.subtitle(grocery),
							secondary: {
								id: 'item-'+grocery.ItemID+'-secondary',
								icon: grocery.ItemID !== 'new' ? {
									events: {
										onclick: function() {
											showItemMenu = grocery.ItemID;
											positionMenuOnActiveItem();
										}
									},
									id: 'item-'+grocery.ItemID+'-ellipsis',
									size: 'small',
									svg: { content: m.trust(ellipsis) }
								} : undefined
							},
							hoverable: true
						}
					)
				);
			}),
			m(
				'div',
				m(
					ListTile,
					{
						title: 'Add a Grocery',
						events: {
							onclick: function() {
								Input = '';
								selectedGroceryIndex = 'new';
								focusGroceryLabel();
							}
						},
						hoverable: true
					}
				),
				selectedGroceryIndex === 'new' ? renderDialog() : ''
			)
		]
	});
};

const routeOptionsForItem = function(ItemID) {
	//const item = Item.find(ItemID);
	let options = [];

	options.push({
		text: 'Edit',
		onclick: function() {
			m.route.set('/items/'+ItemID)
		}
	});

	options.push({
		text: 'Add Notes',
		onclick: function() {
			m.route.set('/notes/'+ItemID)
		}
	});

	/*
	Item.hasValidPrice(item) && options.push({
		text: 'Compare',
		onclick: function() {
			m.route.set('/compare/'+ItemID)
		}
	});
	*/

	options.push({
		text: 'Remove',
		onclick: function() {
			const item = Item.find(ItemID);
			let GroceryState = item.GroceryState;
			GroceryState.Active = false;
			Item.change(item, 'GroceryState', GroceryState);
			state.refreshGroceryState();
		}
	});

	return options;
};

export var Groceries = {
	oninit: function(v) {
		selectedGroceryIndex = false;
	},
	view: function(v) {
		return m(
			Container,
			m(
				'div',
				renderGroceries(),
				m(
					'#item-menu',
					m(
						Menu,
						{
							backdrop: true,
							show: showItemMenu !== false,
							didHide: function() {
								showItemMenu = false;
							},
							content: m(
								List,
								routeOptionsForItem(showItemMenu).map(function(option) {
									return m(
										ListTile,
										{
											events: {
												onclick: option.onclick
											},
											title: option.text,
											ink: true
										}
									);
								})
							)
						}
					)
				)
			)
		);
	}
};

document.body.addEventListener('keyup', function(e) {
	if(e.keyCode == 13 && m.route.get() === '/groceries' && selectedGroceryIndex === 'new') {
		newGrocery();
		m.redraw();
	}
});

addEvent(window, 'scroll', positionMenuOnActiveItem);
addEvent(window, 'resize', positionMenuOnActiveItem);