import m from 'mithril';
import { TextField } from 'polythene-mithril';
import { Container } from '$components';
import { Item } from '$models';
import { config } from '$app/config';
import { state } from '$app/state';

export var EditNote = {
	oninit: function(v) {
		v.state.item = Item.find(v.attrs.id);
	},
	view: function(v) {
		return m(
			Container,
			m(
				'.f3.white',
				v.state.item.Label
			),
			m(
				'.bg-white.br3.pa3',
				m(
					TextField,
					{
						events: {
							oninput: m.withAttr('value', value => {
								Item.change(v.state.item, 'Note', value);
								state.refreshNoteState();
							})
						},
						className: 'resize-v',
						label: 'Notes',
						multiLine: true,
						rows: 20,
						value: v.state.item.Note,
						validate: value => {
							if(value.length > config.max.itemNoteLength) {
								return {
									value: false,
									error: 'Note cannot exceed '+config.max.itemNoteLength+' characters'
								};
							}
						}
					}
				)
			)
		);
	}
};