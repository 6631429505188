import m from 'mithril';
import { Button, TextField } from 'polythene-mithril';

// https://serverless-stack.com/chapters/create-a-billing-form.html

var card;
var name = '';
var isProcessing = false;
var isCardComplete = false;

export var BillingForm = {
	oninit: function(v) {
		card = v.attrs.stripe.elements().create(
			'card',
			{
				style: {
					base: {
						color: '#32325d',
						lineHeight: '18px',
						fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
						fontSmoothing: 'antialiased',
						fontSize: '16px',
						'::placeholder': {
							color: '#aab7c4'
						}
					},
					invalid: {
						color: '#fa755a',
						iconColor: '#fa755a'
					}
				}
			}
		);
	},
	oncreate: function(v) {
		card.mount('#card-element');
	},
	view: function(v) {
		return m(
			'.flex-column',
			m(
				'.ba.b--light-silver.pa3.mb2.w-100.br3.bg-white.relative',
				m(
					'form',
					{
						onsubmit: async function(e) {
							e.preventDefault();	
							isProcessing = true;
							const { token, error } = await v.attrs.stripe.createToken({ name });
							isProcessing = false;
							v.attrs.onsubmit(storage, { token, error });
						},
						action: '/charge',
						method: 'post',
						id: 'payment-form'
					},
					m(
						TextField,
						{
							events: {
								oninput: m.withAttr('value', value => { name = value })
							},
							floatingLabel: true,
							dense: true,
							label: 'Name on Card',
							required: true,
							value: name
						}
					),
					m(
						'.form-row',
						m('#card-element'),
						m('#card-errors', { role: 'alert' })
					)
				)
			),
			m(
				Button,
				{ className: 'green-button round-button self-end' },
				m('span.pa2', 'Submit Payment')
			)
		);
	}
};