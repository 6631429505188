import m from 'mithril';
import stream from 'mithril/stream';
import uuidv1 from 'uuid/v1';
import { Button, Menu, Search, Shadow, TextField } from 'polythene-mithril';

var idsByValue = {};

var cloneOptions = function(options) {
	var clone = JSON.parse(JSON.stringify(options));

	Object.keys(clone).forEach(function(key) {
		clone[key].forEach(function(option) {
			idsByValue[option.value] === undefined && (idsByValue[option.value] = 'option-'+uuidv1());
			option.id = idsByValue[option.value];
		});
	});

	return clone;
};

var onHide = function(v, resetFilter) {
	(resetFilter === undefined || resetFilter) && v.state.filter('');
	v.state.visibleOptions = cloneOptions(v.attrs.options);
	v.state.isOpen(false);
};

export var UnitMenu = {
	oninit: function(v) {
		var options = cloneOptions(v.attrs.options);

		v.state = {
			id: 'menu-'+uuidv1(),
			filter: stream(''),
			isOpen: stream(false),
			options: options,
			visibleOptions: options,
      		selectedMenuValue: stream(v.attrs.value)
		};
	},
	view: function(v) {
		var selectedMenuValue = v.state.selectedMenuValue();

		return m(
			'div',
			[
				m(
					'.relative',
					m(
						TextField,
						{
							hideValidation: true,
							className: 'themed-textfield round-textfield',
							events: {
								onfocus: function() {
									v.state.isOpen(true);
								}
							},
							floatingLabel: true,
							dense: true,
							label: v.attrs.label,
							required: true,
							value: v.attrs.text
						}
					),
					m(
						'.absolute.top-0.left-0.right-0.bottom-0.pointer',
						{
							onclick: () => {
								v.state.isOpen(true);
							}
						}
					)
				),
				m(
					Menu,
					{
						backdrop: true,
						style: {
							'max-height': '100vh'
						},
						topMenu: true,
						className: 'mh2',
						show: v.state.isOpen(),
						didShow: () => {							
							var wrapper = document.querySelector('#'+v.state.id);
							if(wrapper) {
								var input = wrapper.querySelectorAll('input[type="text"]');
								input.length && input[0].focus();
							}
						},
						didHide: () => {
							onHide(v);
						},
						content: [
							m(
								Search,
								{
									style: 'pointer-events: none;',
									events: {
										oninput: e => {
											v.state.filter(e.target.value);
											var lowercase = e.target.value.toLowerCase();
											var options = v.state.options;
											var visibleOptions = {};
											Object.keys(options).forEach(function(key) {
												var optionsForKey = options[key].filter(function(option) {
													return option.value.indexOf(lowercase) >= 0 || (option.value !== option.text && option.text.toLowerCase().indexOf(lowercase) >= 0);
												});

												optionsForKey.length && (visibleOptions[key] = optionsForKey);
											});

											v.state.visibleOptions = visibleOptions;
										}
									},
									id: v.state.id,
									fullWidth: true,
									textfield: { label: 'Search' },
									before: m(Shadow),
									value: v.state.filter()
								}
							),
							Object.keys(v.state.visibleOptions).map(
								function(key) {																
									return [
										m(
											'div.pa3.flex-row',
											m(
												'b',
												{
													style: 'margin-top: .9rem;'
												},
												key
											),
											m(
												'.flex-auto.pl2',
												v.state.visibleOptions[key].map(function(option) {
													return m(
														Button,
														{
															className: 'mh1',
															events: {
																onclick: () => {
																	v.attrs.onchange(option.value);
																	v.state.selectedMenuValue(option.value);
																}
															},
															selected: option.value === selectedMenuValue
														},
														m(
															'span',
															{
																className: 'pa2'
															},
															option.text
														)
													);
												})
											)
										)
									];
								}
							)
						]
					}
				)
			]
	  	)
	}
};