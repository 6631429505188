import m from 'mithril';
import { Button } from 'polythene-mithril';
import { Container } from '$components';
import { Credentials } from '$models';
import { config } from '$app/config';

const renderSignupButton = function() {
	return m(
		Button,
		{
			className: 'green-button round-button mt4',
			events: {
				onclick: function() {
					m.route.set('/signup');
				}
			},
			style: 'width: 10rem;'
		},
		m('span.pa2', 'Sign up')
	);
};

const renderUpgradeButton = function() {
	return m(
		Button,
		{
			className: 'green-button round-button mt4',
			events: {
				onclick: function() {
					m.route.set('/upgrade');
				}
			},
			style: 'width: 10rem;'
		},
		m('span.pa2', 'Upgrade')
	);
};

export var Perks = {
	view: function(v) {
		return m(
			Container,
			m(
				'.f3.white',
				'For a one time payment of '+config.costString+' dollars, become a Paid User, remove limits, and open access to Notes.'
			),
			Credentials.authenticated
				? renderUpgradeButton()
				: renderSignupButton()
		);
	}
};